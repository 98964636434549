<template>
  <div>
     <el-row :gutter="20">
        <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
          <div class="dashboard_first">
            <img src="../../assets/accounting/account_1.png" />

            <span class="dashboard_content">
              终端总数
              <span class="dashboard_content_number">
                <countTo :startVal="0" :endVal="1000"></countTo>
              </span>
              台
            </span>
          </div>
        </el-col>
        <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
          <div class="dashboard_second">
            <img src="../../assets/accounting/account_2.png" />
            <span class="dashboard_content">
              在线终端数量
              <span class="dashboard_content_number">
                <countTo :startVal="0" :endVal="30">30</countTo>
              </span>
              台
            </span>
          </div>
        </el-col>
        <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
          <div class="dashboard_third">
            <img src="../../assets/accounting/account_3.png" />
            <span class="dashboard_content">
              今日开机数量
              <span class="dashboard_content_number">
                <countTo :startVal="0" :endVal="30">30</countTo>
              </span>个
            </span>
          </div>
        </el-col>
        <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
          <div class="dashboard_fourth">
            <img src="../../assets/accounting/account_4.png" />
            <span class="dashboard_content">
              平均在线时长
              <span class="dashboard_content_number">
                <countTo :startVal="0" :endVal="30">30</countTo>
              </span>分钟
            </span>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" :offset="0">
          <div class="echart-pie-wrap">
            <LineChart :chartData="chartData1" />
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" :offset="0">
          <div class="echart-pie-wrap">
            <BarChart :chartData="chartData1" :charDataX="charDataX" />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8" :offset="0">
          <div class="list_card">
            <div class="list_card_title">节目排行</div>
            <div v-for="(item,index) in 9" :key="index" class="list_display">
              <div>
                {{index+1}}.
                名称
              </div>
              <div>5小时</div>
            </div>
          </div>
        </el-col>
        <el-col :span="8" :offset="0">
          <div class="list_card">
            <div class="list_card_title">应用排行</div>
            <div v-for="(item,index) in 9" :key="index" class="list_display">
              <div>
                {{index+1}}.
                名称
              </div>
              <div>5小时</div>
            </div>
          </div>
        </el-col>
        <el-col :span="8" :offset="0">
          <div class="list_card">
            <div class="list_card_title">最新资讯</div>
            <div v-for="(item,index) in 20" :key="index" class="list_display">
              <div>
                {{index+1}}.
                名称
              </div>
              <div>5小时</div>
            </div>
          </div>
        </el-col>
      </el-row>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
export default {
  components: {
    countTo,
    LineChart,
    BarChart
  },
  created() {},
  data() {
    return {
      chartData1: [5, 20, 36, 10, 10, 20, 15, 20, 55],
      charDataX: ["1号", "2号", "3号", "4号", "5号", "6号", "7号", "8号", "9号"]
    };
  }
};
</script>
<style lang='scss'>

//当屏幕小于1920时应用当前样式
@media screen and (max-width: 1921px) {
  .dashboard_content {
    margin-left: 50px;
    font-size: 14px;
    .dashboard_content_number {
      padding: 0 10px;
      font-size: 40px;
      color: #ffa155;
    }
  }
  .dashboard_first {
    background: #ffeddd;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .dashboard_first:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .dashboard_second {
    background: #dff2ff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .dashboard_second:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .dashboard_third {
    background: #e5f9dd;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .dashboard_third:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .dashboard_fourth {
    background: #fae8ff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .dashboard_fourth:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .echart-pie-wrap {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  .echart-pie-wrap:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .list_card {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    overflow-y: auto;
    height: 390px;
  }
  .list_card:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .list_card_title {
    font-size: 18px;
    font-weight: 700;
    color: #464646;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  .list_display {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
  }
  .list_display:hover {
    background: #eee;
  }
}

//当屏幕大于1920时应用当前样式
@media screen and (min-width: 1921px) {
  .dashboard_content {
    margin-left: 50px;
    font-size: 18px;
    .dashboard_content_number {
      padding: 0 10px;
      font-size: 60px;
      color: #ffa155;
    }
  }
  .dashboard_first {
    background: #ffeddd;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    height: 200px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .dashboard_first:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .dashboard_second {
    background: #dff2ff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    height: 200px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .dashboard_second:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .dashboard_third {
    background: #e5f9dd;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    height: 200px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .dashboard_third:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .dashboard_fourth {
    background: #fae8ff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 #c0c4cc;
    height: 200px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .dashboard_fourth:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .echart-pie-wrap {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  .echart-pie-wrap:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .list_card {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    overflow-y: auto;
    height: 520px;
  }
  .list_card:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }
  .list_card_title {
    font-size: 18px;
    font-weight: 700;
    color: #464646;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  .list_display {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
  }
  .list_display:hover {
    background: #eee;
  }
}
</style>