<template>
  <div>
    <div class="chartSize" :id="echarts"  ref="echarts"></div>
  </div>
</template>

<script>
// 引入echarts
import * as echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(this.echarts));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "24小时在线数量"
        },
        tooltip: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        yAxis: {},
         grid:{
          left:'0%',
          right:'0%',
          bottom:'3%',
          containLabel:true
        },
        series: [
          {
            type: "line",
            smooth: true,
            data: this.chartData,
            areaStyle: {
              color:{
                type:'linear',
                x:0,y:0,x2:0,
                y2:1,
                colorStops:[
                  {
                    offset:0,
                    color:'#025cdf',
                  },
                  {
                    offset:1,
                    color:'#4b90f3'
                  }
                ],
                global:false
              }
            }
          }
        ]
      });
    }
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawChart();
    });
  },
  created: () => {}
};
</script>

<style lang='scss'>
//当屏幕小于1920时应用当前样式
@media screen and (max-width: 1921px) {
  .chartSize {
    height: 250px;
  }
}
//当屏幕大于1920时应用当前样式
@media screen and (min-width: 1921px) {
  .chartSize {
    height: 400px;
  }
}
</style>